import './Reset.scss';
import { Button, ModalBox, Alert } from '@/components/UI';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
export default defineComponent({
    name: "Reset",
    components: {
        Alert,
        Button,
        ModalBox
    },
    setup: () => ({
        toast: useToast()
    }),
    data: () => ({
        password: '',
        confirmVisible: false,
        countDown: 0
    }),
    methods: {
        async resetBackend() {
            this.confirmVisible = false;
            const success = await this.$store.direct.dispatch.hardware.reset({ password: this.password });
            if (success) {
                this.toast.success('Reset successful');
                this.countDown = 60;
                setInterval(() => {
                    this.countDown--;
                    if (this.countDown === 0) {
                        this.$router.push('/');
                    }
                }, 1000);
            }
            else {
                this.toast.error('Reset failed - password incorrect');
            }
        }
    }
});
